<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :title="item.name" :image="item.image" back="Convention.Schedule.Contests">

		<app-content-head-actions-item text="Scan" icon="scan" v-on:click="onScanClick" v-if="canScan" />
		<app-content-head-actions-item text="Unregister" :active="true" :loading="is.registering" icon="register" v-on:click="onUnregisterClick" v-if="item.session.registered && canRegister" />
		<app-content-head-actions-item text="Register" :loading="is.registering" icon="register" v-on:click="onRegisterClick" v-if="!item.session.registered && canRegister" />

	</app-content-head>

	<app-content-alert :success="item.session.registered" v-if="hasRegistration">

		<template v-if="item.session.registered">

			You have successfully registered to be part of this contest.

		</template>

		<template v-if="!canRegister && !item.session.registered">

			This contest required participants to register before it began. <b>You can no longer register.</b>

		</template>

		<template v-if="canRegister && !item.session.registered">

			This contest requires participants to register in order to take part once it begins. <b>Please register so you don't miss out.</b>

		</template>

	</app-content-alert>

	<app-content-body :is-grid="4" :loading="is.loading">

		<app-widget-summary :headless="true" :span="3" :text="item.description" :metrics="metrics" />

		<app-widget-info :items="info" />

		<app-widget-chat :id="item.id" :span="2" />

		<com-leaders :data="data.leaders.collection" :eligibility="item.eligibility" />

		<com-winners :data="data.winners.collection" v-if="data.winners.total" />

		<com-prizes :data="data.prizes.collection" v-if="data.prizes.total" />

	</app-content-body>

</app-content>

</template>

<script>

import mixItem from '@/mixin/item'

import comLeaders from './conventionschedulecontest/Leaders.vue'
import comWinners from './conventionschedulecontest/Winners.vue'
import comPrizes from './conventionschedulecontest/Prizes.vue'

export default {

	mixins: [mixItem],

	components: {
		comLeaders,
		comWinners,
		comPrizes
	},

	data: function() {

		return {
			data: {
				leaders: {},
				prizes: {},
				winners: {}
			},
			is: {
				registering: false
			},
			endpoint: 'convention/schedule/contest',
			live: 'convention/schedule/contest'
		}

	},

	computed: {
		
		canScan: function() {

			return (!this.hasRegistration || (this.hasRegistration && this.item.session.registered)) && this.item.status === this.$constants.schedule.contest.status.active

		},

		canRegister: function() {

			return this.hasRegistration && this.item.status === this.$constants.schedule.contest.status.registration

		},

		hasRegistration: function() {

			return this.item.type === this.$constants.schedule.contest.type.register

		},

		metrics: function() {

			return [
				{
					disabled: !this.item.from,
					text: (this.item.from < this.$time) ? 'Started on' : 'Starts at',
					value: this.$filter('localDate', this.item.from, 'h:mma, Do MMMM'),
					type: 'info'
				},
				{
					disabled: !this.item.to,
					text: (this.item.to < this.$time) ? 'Ended at' : 'Ends at',
					value: this.$filter('localDate', this.item.to, 'h:mma, Do MMMM'),
					type: 'info'
				},
				{
					disabled: !this.item.auto,
					text: (this.item.auto < this.$time) ? 'Awarded at' : 'Awards at',
					value: this.$filter('localDate', this.item.auto, 'h:mma, Do MMMM'),
					type: 'info'
				}
			]

		},

		info: function() {

			return [
				{
					icon: 'participants',
					label: 'Participants',
					text: this.item.count.participants
				},
				{
					disabled: !this.item.eligibility,
					icon: 'eligible',
					label: 'Eligible to win',
					text: this.item.count.eligible
				},
				{
					icon: 'prize',
					label: 'Prizes',
					text: this.item.count.prizes
				},
				{
					icon: 'winner',
					label: 'Winners picked',
					text: this.item.count.winners
				}
			]

		}

	},

	methods: {

		onScanClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Contests.Scan'
			})

		},

		onRegisterClick: function() {

			this.is.registering = true

			this.$api.post(['convention/schedule/contest', this.item.id, 'register']).then(function() {

				this.is.registering = false

			}.bind(this))

		},

		onUnregisterClick: function() {

			this.is.registering = true

			this.$api.delete(['convention/schedule/contest', this.item.id, 'register']).then(function() {

				this.is.registering = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>
<template>

<app-content-box :span="2" :data-span="2" :is-empty="!data.length" empty-message="No progress logged yet." class="leaders" title="Leaders" icon="leaders" :not-padded="true">

	<div class="leaders-item" v-for="(leader, index) in data" :key="index">

		<div class="leaders-item-avatar" v-bgimage="leader.image">

			<app-icon icon="user" v-if="!leader.image" />

		</div>

		<div class="leaders-item-name"><span>{{ leader.name }}</span><span class="label" v-if="leader.eligible && eligibility">Eligible</span></div>

		<div class="leaders-item-count">{{ leader.score }}</div>

	</div>

</app-content-box>

</template>

<script>

export default {

	name: 'leaders',

	props: ['data', 'eligibility']

}

</script>

<style scoped>

.leaders[data-span="2"] {
	grid-column: 3 / 5;
}

.is-mobile .leaders {
	grid-column: 1 / 5;
}

.leaders-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 5px 10px;
	border-bottom: 1px solid #eee;
}

.leaders-item:first-child {
	padding-top: 10px;
}

.leaders-item:last-child {
	padding-bottom: 10px;
	border-bottom: 0px;
}

.leaders-item-avatar {
	width: 40px;
	height: 40px;
	border-radius: 4px;
	background-color: #eee;
	background-position: 50% 50%;
	background-size: cover;
	margin-right: 10px;
	text-align: center;
	flex-shrink: 0;
	line-height: 40px;
	color: #999;
	font-size: 16px;
}

.leaders-item-name {
	font-weight: 500;
	color: #687589;
	font-size: 20px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.leaders-item-name span.label {
	font-size: 12px;
	line-height: 12px;
	padding: 2px 4px;
	background-color: #4082d3;
	margin-top: 2px;
	color: #fff;
	border-radius: 4px;
}

.leaders-item-count {
	font-weight: 500;
	color: #fff;
	font-size: 20px;
	min-width: 40px;
	line-height: 40px;
	flex-shrink: 0;
	padding: 0px 10px;
	border-radius: 4px;
	text-align: center;
	background-color: #4082d3;
}

</style>
